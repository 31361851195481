import axios from 'axios'
import store from '../store/index'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
if(sessionStorage.getItem("token") != '' && sessionStorage.getItem("token") != null ){
    axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
}


export function myHttp(url, method, params = {}) {
  // 首先判断是get请求还是post请求
  axios.defaults.headers.common['token'] = sessionStorage.getItem("token") || ''
  let data = method.toLocaleLowerCase() === 'post' ? 'data' : 'params';
  // console.log(data, 'data!!!!!!!');
  if(data == 'params'){
    return axios({
      method,
      url: global.posturl.host + global.posturl[url] +'/' + params
    }).then((res) => {
      return Promise.resolve(res.data);
    }).catch((err) => {
      return Promise.reject(err);
    })
  }else{
    return axios({
      method,
      url: global.posturl.host + global.posturl[url],
      [data]: params // 差异点在于data的值
    }).then((res) => {
      return Promise.resolve(res.data);
    }).catch((err) => {
      return Promise.reject(err);
    })
  }

}

// 不需要添加url
export function newPost(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
        let _this = this
        // console.log(`${url}接口发起请求，请求参数：`, data)
        axios.post(global.posturl.host + url, data)
            .then(response => {
                resolve(response.data);
                if(response.data.msg && response.data.msg != ''){
                    _this.$message({
                        type: 'success',
                        message: response.data.msg,
                        duration: 3000
                    })
                }
            }, err => {
                reject(err);
                _this.$message({
                    type: 'error',
                    message: '网络错误1',
                    duration: 5000
                });
            }).catch(() => {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
        })
    })
}

export function newGet(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
        let _this = this
        // console.log(`${url}接口发起请求，请求参数：`, data)
        axios.get(global.posturl.host + url)
            .then(response => {
                resolve(response.data);
                if(response.data.msg && response.data.msg != ''){
                    _this.$message({
                        type: 'success',
                        message: response.data.msg,
                        duration: 3000
                    })
                }
            }, err => {
                reject(err);
                _this.$message({
                    type: 'error',
                    message: '网络错误1',
                    duration: 5000
                });
            }).catch(() => {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
        })
    })
}

export function newDelete(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
        let _this = this
        // console.log(`${url}接口发起请求，请求参数：`, data)
        axios.delete(global.posturl.host + url)
            .then(response => {
                resolve(response.data);
                if(response.data.msg && response.data.msg != ''){
                    _this.$message({
                        type: 'success',
                        message: response.data.msg,
                        duration: 3000
                    })
                }
            }, err => {
                reject(err);
                _this.$message({
                    type: 'error',
                    message: '网络错误1',
                    duration: 5000
                });
            }).catch(() => {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
        })
    })
}



export function myPost(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
        let _this = this
        // console.log(`${url}接口发起请求，请求参数：`, data)
        axios.post(global.posturl.host + global.posturl[url], data)
            .then(response => {
                resolve(response.data);
                if(response.data.msg && response.data.msg != ''){
                    _this.$message({
                        type: 'success',
                        message: response.data.msg,
                        duration: 3000
                    })
                }
            }, err => {
                reject(err);
                _this.$message({
                    type: 'error',
                    message: '网络错误1',
                    duration: 5000
                });
            }).catch(() => {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
        })
    })
}

export function myGet(url) {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
        let _this = this
        // console.log(`${url}接口发起请求，请求参数：`, data)
        axios.get(global.posturl.host + global.posturl[url])
            .then(response => {
                resolve(response.data);
                if(response.data.msg && response.data.msg != ''){
                    _this.$message({
                        type: 'success',
                        message: response.data.message,
                        duration: 3000
                    })
                }
            }, err => {
                reject(err);
                _this.$message({
                    type: 'error',
                    message: '网络错误1',
                    duration: 5000
                });
            }).catch(() => {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
        })
    })
}

export function getReportGet(url, data = {}) {
    return new Promise((resolve, reject) => {

      let _this = this

      let HTTP_AXIOS = axios.create()

       HTTP_AXIOS ({
          method: 'get',
          url: global.posturl.host + url,
          timeout: 180000
        }).then(res => {
          
          resolve(response.data);
          console.log(1111)
          if(response.data.msg && response.data.msg != ''){
              _this.$message({
                  type: 'success',
                  message: response.data.msg,
                  duration: 3000
              })
          }
        }).catch(error => {
          if (error.config.timeout == 180000) {
            _this.$message({
                type: 'error',
                message: '请求超时，请检查网络',
                duration: 5000
            });
          } else {
            _this.$message({
                type: 'error',
                message: '网络错误2',
                duration: 5000
            });
          }
        })


    })
}
