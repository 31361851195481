var posturl = {
  // host: 'http://monitor.api.cqxddwl.com',
  //  host: 'http://localhost:8083/monitor/user',
  //  host: 'https://cqdye.cqdyep.com/monitor/user/test',
   host: 'https://cqdyf.cqdyep.com/monitor/user/admin',
  //  host: 'https://cqdye.cqdyep.com/monitor/test',
  // host: 'https://cqdye.cqdyep.com/monitor',
  //大屏
  // 登录
  login: '/index/login',
  // 入库管理
  rkgl: '/warehousing/page',
  // 新增入库
  addrk: '/warehousing/save',
  // 修改入库
  edrk:'/warehousing/update',
  // 灭活
  ztrk:'/warehousing/update/state',
  //公司管理员账号列表
  gsgly: '/admin/page',
  //修改管理员密码
  updgly: '/admin/update',
  //修改管理员权限
  qxgly: '/admin/update/state',
  //修改公司管理员权限
  qxgly_1: '/admin/update/permission',
  //新增公司管理员
  addgly: '/admin/save',
  //删除公司管理员
  delgsgly: '/admin/delete',
  //施工人员账号管理列表
  sgryzhgl: '/employee/page',
  // 新增施工人员
  addsgr:'/employee/save',
  // 修改施工人密码
  edsgr:'/employee/update',
  // 修改施工人状态
  ztsgr:"/employee/update/state",
  //删除施工人员账号管理列表
  delsgry: '/employee/delete',
  //客户管理列表
  khgl: '/customer/page',
  // 新增客户
  addkh:'/customer/save',
  // 修改客户密码
  edkh:'/customer/update',
  // 修改客户状态
  ztkh:'/customer/update/state',
  //删除客户
  delkhgl: '/customer/delete',
  //告警信息
  gjxx: '/warning/record/page',
  // 分页查询项目
  xmcx:'/project/page',
  // 层级管理
  cjgl:'/level/page',
  // 设备管理
  sbgl:'/equipment/page',
  // 根据设备id查询能源设备告警设置
  nyjg:'/warning/record/energy/settings',
  // 根据设备id查询环境设备告警设置
  hjjg:'/warning/record/environment/settings',
  // 新增告警人员
  addgjry:'/warning/record/warning/personnel/save',
  // 删除告警人员
  delgjry:'/warning/record/warning/personnel/delete',
  // 修改告警人员
  edgjry:'/warning/record/warning/personnel/update',
  // 查询告警人员
  fdgjry:'/warning/record/warning/personnel',
  // 修改环境告警
  edhj:'/warning/record/update/environment/settings',
  // 修改能源告警
  edny:'/warning/record/update/energy/settings',
  // 分页查询施工管理
  sggl:'/construction/project/page',
  // 项目详情
  xmxq:'/construction/project/info',
  // 分页查询施工设备列表
  sblb:'/construction/page',
  // 施工id查询施工设备基本信息
  sgsb:'/construction/equipment/info',
  // 施工id查询安装信息
  sgaz:'/construction/install/info',
  // 施工id查询施工步骤
  sgbz:'/construction/steps',
  // 上传图片
  sctp:'/upload/image',
  // 修改施工步骤
  edsgbz:'/construction/steps/update',
  // 修改项目
  edxm:'/project/update',
  // 修改设备信息
  edsbxx:'/construction/equipment/update',
  // 修改安装信息
  edazxx:'/construction/install/update',
  // 导出日志列表
  eplog:'/export/log/page',
  // 查询设备类别列表
  cxsb:'/equipment/type/list',
  // 查询安装工艺列表
  cxgy:'/install/workmanship/list',
  // 添加安装工艺
  addgy:'/install/workmanship/save',
  // 修改工艺步骤
  edgy:'/install/workmanship/update',
  // 删除工艺
  delgy:'/install/workmanship/delete',
  // 新增设备
  adsb:'/equipment/type/save',
  // 施工管理生成报告
  sgbg:'/construction/create/report',
  // 数据库备份
  sjkbf:'/db/back/up',
  // 获取用户信息
  hqxx:'/index/info',
  // 获取验证码
  code:'/index/sendMessage',
  // 忘记密码
  wjmm:'/index/forgetPassword',
  // 验证手机
  yzsj:'/phone/judge'
}

global.posturl = posturl
