import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    // redirect: '/HjsjIndex1',
    component: resolve => require(['@/views/Home'], resolve),
    children: [
      // 在线检测
      // 环境数据
      {
        path: '/HjsjIndex1',
        name: 'HjsjIndex1',
        component: resolve => require(['@/views/ZXJC/HJSJ/index'], resolve)
      },
      {
        path: '/HjsjIndex2',
        name: 'HjsjIndex2',
        component: resolve => require(['@/views/ZXJC/HJSJ/index2'], resolve)
      },
      {
        path: '/XmglIndex',
        name: 'XmglIndex',
        component: resolve => require(['@/views/XMGL/index'], resolve)
      },
      // 设备管理
      // 设备信息管理
      {
        path: '/SbglSbxxgl',
        name: 'SbglSbxxgl',
        component: resolve => require(['@/views/SBGL/sbxxgl'], resolve)
      },
      // 设备列表
      {
        path: '/EquipmentList',
        name: 'EquipmentList',
        component: resolve => require(['@/views/SBGL/equipmentList'], resolve)
      },
      // 新增设备管理
      {
        path: '/SbglXzsbgl',
        name: 'SbglXzsbgl',
        component: resolve => require(['@/views/SBGL/xzsbgl'], resolve)
      },
	  // 告警信息
	  {
	    path: '/gjxx',
	    name: 'gjxx',
	    component: resolve => require(['@/views/GJGL/gjxx'], resolve)
	  },
    // 告警详情
    {
      path: '/gjxx1',
      name: 'gjxx1',
      component: resolve => require(['@/views/GJGL/gjxx1'], resolve)
    },
    // 告警设置
    {
      path: '/gjsz',
      name: 'gjsz',
      component: resolve => require(['@/views/GJGL/gjsz'], resolve)
    },
    // 告警设置
    {
      path: '/WarningSet',
      name: 'warningSet',
      component: resolve => require(['@/views/GJGL/warningSet'], resolve)
    },
    // 告警设置-1
    {
      path: '/gjsz_1',
      name: 'gjsz_1',
      component: resolve => require(['@/views/GJGL/gjsz_1'], resolve)
    },
     // 告警设置-2
    {
      path: '/gjsz_2',
      name: 'gjsz_2',
      component: resolve => require(['@/views/GJGL/gjsz_2'], resolve)
    },
    // 告警设置-3
    {
      path: '/gjsz_3',
      name: 'gjsz_3',
      component: resolve => require(['@/views/GJGL/gjsz_3'], resolve)
    },
    // 告警设置-4
    {
      path: '/gjsz_4',
      name: 'gjsz_4',
      component: resolve => require(['@/views/GJGL/gjsz_4'], resolve)
    },
    // 告警设置-5
    {
      path: '/gjsz_5',
      name: 'gjsz_5',
      component: resolve => require(['@/views/GJGL/gjsz_5'], resolve)
    },
    // 施工管理
    {
      path: '/sggl',
      name: 'sggl',
      component: resolve => require(['@/views/SGGL/sggl'], resolve)
    },
    // 施工管理
    {
      path: '/sggl_1',
      name: 'sggl_1',
      component: resolve => require(['@/views/SGGL/sggl_1'], resolve)
    },
    // 施工管理
    {
      path: '/sggl_2',
      name: 'sggl_2',
      component: resolve => require(['@/views/SGGL/sggl_2'], resolve)
    },
    //管理客户
    {
      path: '/glkh',
      name: 'glkh',
      component: resolve => require(['@/views/ZHGL/glkh'], resolve)
    },
    //历史数据
    {
      path: '/lssj',
      name: 'lssj',
      component: resolve => require(['@/views/xtgl/equipmentList'], resolve)
    },
    //操作日志
    {
      path: '/czrz',
      name: 'czrz',
      component: resolve => require(['@/views/xtgl/sysLogList'], resolve)
    },
    {
      path: '/about',
      name: 'About',
      component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    // 地图
    {
      path: '/dt',
      name: 'dt',
      component: resolve => require(['@/views/dt'], resolve)
    },
    {
      path: '/selectPoint',
      name: 'selectPoint',
      component: resolve => require(['@/views/selectPoint'], resolve)
    },

    ]
  },

  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login'], resolve)
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

	if (to.path === '/login') {
		next();
	} else {
		var token = sessionStorage.getItem('token')
		if (token == null || token == '') {
			next('/login');
		} else {
			next();
		}
	}
});

export default router
